import * as L from "leaflet";
import "leaflet/dist/leaflet.css";

export default defineNuxtPlugin(() => {

    const customIcon = L.icon({
        iconUrl: "/leaflet/images/marker-icon.png",
        shadowUrl: "/leaflet/images/marker-shadow.png",
        // iconSize: [32, 32], // Width and height in pixels
        // iconAnchor: [16, 32], // Position where the marker anchors on the map
        // popupAnchor: [0, -32], // Position where the popup appears relative to the icon
    });

    return {
        provide: {
            L,
            customIcon
        },
    };
});

