import L from "leaflet";

export default defineNuxtPlugin(() => {
    L.WindParticles = L.Layer.extend({
        options: {
            particleCount: 50,        // Default particle count
            particleLifetime: 10000,     // Particle lifetime in milliseconds
            particleLength: 10,         // Length of particles in pixels
            particleWidth: 1,           // Width of particles in pixels
            particleColor: "#FFFFFF",   // Color of particles
            particleOpacity: 0.4,       // Opacity of particles
            windSpeed: 2,               // Wind speed factor
            windDirection: 0,          // Wind direction in degrees (0 = North, 90 = East)
            fadeOut: true,              // Whether particles should fade out
            radius: 80,                // Radius of particle area
            maxParticles: 5000,         // Maximum number of particles to display (for performance)
            useRandomSizes: true,       // Whether to randomize particle sizes
            useRandomSpeeds: true,      // Whether to randomize particle speeds
            useRandomDirections: true,  // Whether to randomize wind direction slightly
            useRandomPositions: true    // Whether to randomize initial positions
        },

        initialize: function (latlng, options) {
            L.setOptions(this, options);
            this._latlng = latlng;
            this._particles = [];
            this._running = false;
            this._frameCount = 0;
            this._canvasSize = this.options.radius * 3; // Make canvas large enough for all particles
        },

        onAdd: function (map) {
            this._map = map;

            // Create the container
            this._container = L.DomUtil.create("div", "wind-particles-container");
            this._container.style.position = "absolute";
            this._container.style.pointerEvents = "none"; // Make it clickthrough

            // Create canvas element
            this._canvas = document.createElement("canvas");
            this._canvas.width = this._canvasSize;
            this._canvas.height = this._canvasSize;
            this._canvas.style.position = "absolute";
            this._canvas.style.left = `-${this._canvasSize / 2}px`;
            this._canvas.style.top = `-${this._canvasSize / 2}px`;

            this._container.appendChild(this._canvas);
            this._ctx = this._canvas.getContext("2d");

            map.getPanes().overlayPane.appendChild(this._container);
            this._updatePosition();

            // Initialize particles
            this._initParticles();

            // Start animation
            this._running = true;
            this._animate();

            // Listen for all relevant map events
            map.on("zoom zoomend viewreset moveend move dragend", this._updatePosition, this);
        },

        onRemove: function (map) {
            this._running = false;
            L.DomUtil.remove(this._container);
            map.off("zoom zoomend viewreset moveend move dragend", this._updatePosition, this);

            // Clear particles
            this._particles = [];
        },

        _updatePosition: function () {
            if (!this._map) return;
            const point = this._map.latLngToLayerPoint(this._latlng);

            // Position the container at the exact location
            L.DomUtil.setPosition(this._container, point);
        },

        _initParticles: function () {
            // Generate initial particles at random positions
            const count = Math.min(this.options.particleCount, this.options.maxParticles);
            const now = Date.now();

            for (let i = 0; i < count; i++) {
                this._particles.push(this._createParticle(now - Math.random() * this.options.particleLifetime));
            }
        },

        _createParticle: function (creationTime = Date.now()) {
            // Position calculation
            let angle, distance;

            if (this.options.useRandomPositions) {
                // Random position within radius
                angle = Math.random() * Math.PI * 2;
                distance = Math.random() * this.options.radius;
            } else {
                // Evenly distributed positions
                // Use particle count to create a grid or circle pattern
                const particleIndex = this._particles.length % 8; // 8 directions
                angle = particleIndex * (Math.PI / 4);
                distance = this.options.radius * 0.7; // Fixed distance from center
            }

            // Size calculation
            const length = this.options.useRandomSizes
                ? this.options.particleLength * (0.7 + Math.random() * 0.6)
                : this.options.particleLength;

            const width = this.options.useRandomSizes
                ? this.options.particleWidth * (0.7 + Math.random() * 0.6)
                : this.options.particleWidth;

            // Speed calculation
            const speedFactor = this.options.useRandomSpeeds
                ? this.options.windSpeed * (0.7 + Math.random() * 0.6)
                : this.options.windSpeed / 8;

            // Direction variation calculation
            const directionOffset = this.options.useRandomDirections
                ? (Math.random() - 0.5) * 20 * (Math.PI / 180) // +/- 10 degrees
                : 0;

            return {
                x: Math.cos(angle) * distance,
                y: Math.sin(angle) * distance,
                length: length,
                width: width,
                age: 0,
                created: creationTime,
                speedFactor: speedFactor,
                directionOffset: directionOffset,
                opacity: this.options.particleOpacity
            };
        },

        _drawParticle: function (p) {
            const ctx = this._ctx;
            const centerX = this._canvasSize / 2;
            const centerY = this._canvasSize / 2;

            // Base wind direction in radians
            // const windRad = (this.options.windDirection * Math.PI) / 180;

            const windRad = ((this.options.windDirection - 90) * Math.PI) / 180;

            // Individual particle direction
            const particleRad = windRad + p.directionOffset;

            // Draw teardrop shape
            ctx.save();

            // Move to particle position
            ctx.translate(centerX + p.x, centerY + p.y);

            // Rotate to match wind direction
            ctx.rotate(particleRad);

            // Set fill style with opacity
            ctx.fillStyle = this._getRGBA(this.options.particleColor, p.opacity);

            // Draw teardrop shape
            ctx.beginPath();

            // Teardrop head (wider part) pointing in wind direction
            const headWidth = p.width;
            const tailWidth = p.width * 0.3;
            const particleLength = p.length;

            // Create teardrop path
            ctx.moveTo(particleLength / 2, 0); // Tip of teardrop (front)

            // Right side curve
            ctx.quadraticCurveTo(
                particleLength / 4, headWidth / 2,
                -particleLength / 2, 0
            );

            // Left side curve
            ctx.quadraticCurveTo(
                particleLength / 4, -headWidth / 2,
                particleLength / 2, 0
            );

            ctx.closePath();
            ctx.fill();

            ctx.restore();
        },

        _getRGBA: function (hexColor, opacity) {
            // Convert hex color to RGBA
            let r = 255, g = 255, b = 255; // Default white

            // Parse hex color if provided
            if (hexColor && hexColor.startsWith('#')) {
                if (hexColor.length === 7) {
                    r = parseInt(hexColor.substr(1, 2), 16);
                    g = parseInt(hexColor.substr(3, 2), 16);
                    b = parseInt(hexColor.substr(5, 2), 16);
                } else if (hexColor.length === 4) {
                    r = parseInt(hexColor.substr(1, 1), 16) * 17;
                    g = parseInt(hexColor.substr(2, 1), 16) * 17;
                    b = parseInt(hexColor.substr(3, 1), 16) * 17;
                }
            }

            return `rgba(${r}, ${g}, ${b}, ${opacity})`;
        },

        _animate: function () {
            if (!this._running) return;

            const now = Date.now();
            // const windRad = (this.options.windDirection * Math.PI) / 180;

            const windRad = ((this.options.windDirection - 90) * Math.PI) / 180;

            // Clear canvas
            this._ctx.clearRect(0, 0, this._canvas.width, this._canvas.height);

            // Update and draw particles
            const centerX = this._canvasSize / 2;
            const centerY = this._canvasSize / 2;
            const canvasRadius = this.options.radius * 1.5; // Slightly larger than visible radius

            // Update existing particles
            for (let i = this._particles.length - 1; i >= 0; i--) {
                const p = this._particles[i];
                const age = now - p.created;

                // Remove particles that have exceeded their lifetime
                if (age > this.options.particleLifetime ||
                    Math.sqrt(p.x * p.x + p.y * p.y) > canvasRadius) {
                    // Remove particle
                    this._particles.splice(i, 1);
                    continue;
                }

                // Update age
                p.age = age;

                // Calculate remaining life as percentage
                const lifePercentage = age / this.options.particleLifetime;

                // Update opacity if fadeOut is enabled
                if (this.options.fadeOut) {
                    p.opacity = this.options.particleOpacity * (1 - lifePercentage);
                }

                // Update position based on wind direction and speed
                const particleWindRad = windRad + p.directionOffset;
                p.x += Math.cos(particleWindRad) * p.speedFactor;
                p.y += Math.sin(particleWindRad) * p.speedFactor;

                // Draw the particle
                this._drawParticle(p);
            }

            // Create new particles to maintain the desired count
            this._frameCount++;

            // Add new particles every few frames to maintain count
            if (this._frameCount % 2 === 0 || this._particles.length < this.options.particleCount / 2) {
                const neededParticles = this.options.particleCount - this._particles.length;
                const batchSize = Math.min(10, neededParticles); // Add up to 10 particles per frame

                if (neededParticles > 0 && this._particles.length < this.options.maxParticles) {
                    for (let i = 0; i < batchSize; i++) {
                        this._particles.push(this._createParticle());
                    }
                }
            }

            // Continue animation
            requestAnimationFrame(this._animate.bind(this));
        }
    });

    L.windParticles = function (latlng, options) {
        return new L.WindParticles(latlng, options);
    };
});